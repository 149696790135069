import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, Observable, from, BehaviorSubject, of } from "rxjs";
import {
  ProjetConfigurationModel,
  ProjetModel,
} from "src/app/shared/models/ProjetModel";
import { EnvironnementModel } from "src/app/shared/models/EnvironnementModel";

@Injectable({ providedIn: "root" })
export class ProjetApiService {
  projetData: ProjetConfigurationModel = null;

  constructor(
    private http: HttpClient,
    @Inject("API_URL") private apiUrl: string
  ) {}

  public getProjetConfiguration(
    nom_projet: string,
    nom_espace: string,
    recharger: boolean = false
  ): Observable<ProjetConfigurationModel> {
    // Vérifier si les données sont déjà en mémoire
    if (!recharger && this.projetData) {
      return of(this.projetData);
    }
    let params = new HttpParams();
    params = params.append("projet", nom_projet);
    params = params.append("espace", nom_espace);
    return this.http
      .get<any>(`${this.apiUrl}projet`, {
        params: params,
      })
      .pipe<ProjetConfigurationModel>(
        map((result) => {
          this.projetData = result.reponse; // Stocker les données en mémoire
          return this.projetData;
        })
      );
  }

  public getProjets(queryParams?: any): Observable<ProjetModel[]> {
    return this.http
      .get<any>(
        `${this.apiUrl}projets`,
        typeof queryParams !== "undefined" ? { params: queryParams } : {}
      )
      .pipe<ProjetModel[]>(map((result) => result.reponse));
  }

  public getEnvironnements(
    nom_projet: string
  ): Observable<EnvironnementModel[]> {
    let params = new HttpParams();

    params = params.append("projet", nom_projet);
    return this.http
      .get<any>(`${this.apiUrl}projet/environnements`, { params: params })
      .pipe<EnvironnementModel[]>(map((result) => result.reponse));
  }
}
